import {Route, Routes} from 'react-router-dom';
// import $ from 'jquery';
import 'jquery/src/jquery';

import Home from './components/Home'
import Contact from './components/Contact'
import About from "./components/About";
import Services from "./components/Services";
import FAQs from "./components/FAQs";
import Login from "./components/Login";
import Register from "./components/Register";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import React, {useEffect} from "react";
import VerifyEmailAddress from "./components/VerifyEmailAddress";
import RequestForgotPassword from "./components/RequestForgotPassword";
import ResetPassword from "./components/ResetPassword";
import BookSurveillance from "./components/BookSurveillance";
import Checkout from "./components/Checkout";
import AdminOperations from "./components/AdminOperations";
import AdminUsers from "./components/AdminUsers";

const Main = () => {

    useEffect(() => {
        const coreScript = document.createElement('script');
        coreScript.src = "../js/core.min.js";
        document.body.appendChild(coreScript);


        const script = document.createElement('script');
        script.src = "../js/script.js";
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(coreScript);
            document.body.removeChild(script);
        }
    }, []);

    return (
        <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/services' element={<Services/>} />
            <Route path="/services/:pathType" element={<Services/>} />
            <Route path='/faqs' element={<FAQs/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/register' element={<Register/>} />
            <Route path='/termsAndConditions' element={<TermsAndConditions/>} />
            <Route path='/privacyPolicy' element={<PrivacyPolicy/>} />
            <Route path='/verifyemailaddress' element={<VerifyEmailAddress/>} />
            <Route path='/forgotpassword' element={<RequestForgotPassword/>} />
            <Route path='/resetpassword' element={<ResetPassword/>} />
            <Route path='/book-surveillance' element={<BookSurveillance/>} />
            <Route path='/checkout' element={<Checkout/>} />
            <Route path='/admin/operations' element={<AdminOperations/>} />
            <Route path='/admin/users' element={<AdminUsers/>} />
        </Routes>
    );
}
export default Main;