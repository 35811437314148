import React, {useEffect} from "react";
import Banner from "./Banner";

import surveillanceServiceImage from '../images/surveillance-default.jpeg';
import surveillanceCheatingServiceImage from '../images/surveillance-cheating.jpeg';
import surveillanceCourtServiceImage from '../images/surveillance-court.jpeg';
import addressTracingServiceImage from '../images/address-tracing.jpeg';
import vehicleTrackingServiceImage from '../images/vehicle-tracking.jpeg';
import processServeServiceImage from '../images/process-serving.jpeg';

import ServiceSection from "./ServiceSection";
import {useLocation, useParams} from "react-router-dom";
import {typeBanner} from "./util/ServiceTypeUtil";
import ReactGA from 'react-ga4';


function Services() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlType = queryParams.get('type')
    const { pathType } = useParams();

    function servicesType(): string | null {
        let type = null;
        if (pathType !== undefined && pathType !== null && pathType.length > 0) {
            type = pathType
        } else if (urlType !== undefined && urlType !== null && urlType.length > 0) {
            type = urlType;
        }
        return type;
    }

    const cheatingServices = ['surveillance', 'vehicleTracking'];
    const courtServices = ['surveillance', 'vehicleTracking', 'addressTracing', 'processServing'];
    const safeServices = ['surveillance', 'addressTracing'];
    const findServices = ['addressTracing'];
    const corporateServices = ['surveillance', 'vehicleTracking', 'processServing'];

    useEffect(() => {
        // Track pageview in Google Analytics
        ReactGA.send({ hitType: 'pageview', page: `/services/${servicesType()}` });
    }, [pathType, urlType]);


    const showService = (service: string) => {
        switch (servicesType()) {
            case 'cheating':
                return cheatingServices.includes(service);
            case 'court':
                return courtServices.includes(service);
            case 'safe':
                return safeServices.includes(service);
            case 'find':
                return findServices.includes(service);
            case 'corporate':
                return corporateServices.includes(service);
            default:
                return true;
        }
    }

    function typeTitle(): string {
        switch (servicesType()) {
            case 'cheating':
                return "I believe someone is being unfaithful";
            case 'court':
                return "I need evidence for court proceedings";
            case 'safe':
                return "I want to make sure someone is safe";
            case 'find':
                return "I need help finding someone";
            case 'corporate':
                return "I have a corporate requirement";
            default:
                return "";
        }
    }

    function forwardTypeParam(): string {
        if (servicesType === undefined || servicesType?.length === 0) {
            return ""
        }
        return "?type=" + servicesType;
    }

    function typeDescription(): string {
        switch (servicesType()) {
            case 'cheating':
                return "If you suspect your partner is cheating, we are here to assist you. We understand this is a sensitive and emotional time, and quickly uncovering the truth can help you regain control and make informed decisions. We offer a range of services tailored to your situation";
            case 'court':
                return "If you require evidence for a court proceeding, it's crucial to gather reliable information quickly. We offer the following services that may be applicable to your legal case";
            case 'safe':
                return "Whether it's checking on the well-being of a loved one or ensuring the safety of a vulnerable individual, having reliable information is essential. With our services, you can take proactive steps to ensure the safety of those you care about. \n" +
                        "We recommend the following services to help you make sure someone is safe";
            case 'find':
                return "If you need help finding someone, whether it's a long-lost relative, a missing person, or someone you need to locate for legal or personal reasons, we're here to assist you. We understand the importance of reuniting with loved ones or obtaining critical information about someone's whereabouts.\n" +
                    "\n" +
                    "We recommend the following services to help you find someone.";
            case 'corporate':
                return "If you have a corporate requirement that involves monitoring the movements of individuals or vehicles, we offer services tailored to meet your needs. We understand the importance of ensuring the safety and security of your assets, employees, and operations.\n" +
                    "\n" +
                    "We provide the following options to fulfil your corporate requirements";
            default:
                return "";
        }
    }

    function surveillanceImage() {
        switch (servicesType()) {
            case 'cheating':
                return surveillanceCheatingServiceImage;
            case 'court':
                return surveillanceCourtServiceImage;
            case 'corporate':
                return surveillanceCourtServiceImage;
            case 'safe':
                return surveillanceServiceImage;
            case 'find':
                return surveillanceServiceImage;
            default:
                return surveillanceServiceImage;
        }
    }

    function surveillanceDescription(): string {
        switch (servicesType()) {
            case 'cheating':
                return "If you're concerned about your partner's activities, we offer discreet covert surveillance services to provide clarity. For instance, if you suspect infidelity due to unexplained absences or suspicious behaviour, our team can help confirm or dispel your doubts. You will receive a detailed covert surveillance report at the end of each surveillance shift. This report will include imagery and comprehensive information about the person's movements.";
            case 'court':
                return "Monitor the movements and actions of individuals discreetly to gather evidence relevant to your case. Our team can customise the deployment with the appropriate number of operatives and hours to suit your specific requirements. At the end of each surveillance shift, you'll receive a detailed report with imagery and comprehensive information about the individual's activities.";
            case 'safe':
                return "In some circumstances it may be appropriate to use surveillance to confirm location of someone or to monitor the movements and activities of individuals to ensure their safety and security. For example, if you have a student going to university and you want to ensure they are adapting well and getting involved in the wrong crowd, or monitoring an elderly family member to make sure they are safe and not experiencing any issues that might require immediate attention. You will receive a detailed surveillance report at the end of each shift, including imagery and information about their movements and activities.";
            case 'corporate':
                return "Our surveillance services allow you to monitor the movements and activities of individuals discreetly. Whether you need to ensure the security of your premises, monitor employee activities, or gather evidence for legal purposes, our surveillance solutions can provide the necessary insights.";
            default:
                return "Covert surveillance is invaluable in various situations. Our trained teams operate to the highest standards across the UK and abroad. Whether it's for suspected infidelity or corporate due diligence, we provide detailed reports with imagery and recordings, all within legal guidelines.";
        }
    }

    function addressTracingDescription(): string {
        switch (servicesType()) {
            case 'court':
                return "Obtain accurate addresses of individuals involved in your case, based on their basic information such as name, phone number, or date of birth. This information can be invaluable for establishing connections and locations relevant to your legal matter.\n";
            case 'safe':
                return "Obtain accurate addresses of individuals to ensure their safety and well-being. Whether you're checking on a loved one's whereabouts or need to locate a lost family member in an emergency, our address tracing service can help provide peace of mind.";
            case 'find':
                return "By providing us with some basic information such as the individual's name and date of birth or their mobile phone number, we can utilise our extensive resources to trace their current UK-based address.\n" +
                    "\n" +
                    "Whether you're trying to reconnect with a long-lost relative, locate someone for legal purposes, or find an individual for personal reasons, our professional service ensures that you receive reliable and up-to-date information. Our team of experts uses advanced search techniques and databases to ensure the highest accuracy in the results we provide.\n";
            default:
                return "Our address tracing service helps locate individuals quickly and efficiently. Whether reconnecting with a lost relative, contacting someone who owes a debt, or finding an old friend, we use advanced techniques to provide accurate and timely results, ensuring confidentiality and reliability.";
        }
    }


    function vehicleTrackingDescription(): string {
        switch (servicesType()) {
            case 'cheating':
                return "If you need to monitor a vehicle's movements discreetly, our covert vehicle tracking service offers a practical solution. For instance, if there are unexplained absences with the family car, real-time tracking can provide the information you need. Our service includes the fitting and removal of the tracker and covers the first week of deployment. You can then decide how many additional weeks you need based on your situation.\n";
            case 'court':
                return "Track the real-time movements of vehicles involved in your case, providing valuable data for legal proceedings. Our service includes the installation and removal of tracking devices, along with the first week of use, and offers flexible deployment options to meet your needs.";
            case 'corporate':
                return "Track the real-time movements of corporate vehicles to ensure efficient fleet management and security. Our vehicle tracking services allow you to monitor the location and routes taken by vehicles in real time, providing valuable data for operational efficiency and security.";
            default:
                return "Covert vehicle tracking helps understand someone's movements. Ideal for monitoring employee vehicle use or clarifying a partner's activities, our service includes tracker deployment, one week of monitoring, and removal, with additional weeks available. We operate within legal guidelines for accurate information.";
        }
    }

    function processServingDescription(): string {
        switch (servicesType()) {
            case 'court':
                return "Ensure legal documents are delivered to the intended recipients promptly and professionally. Our process serving team can handle the serving of court orders, such as non-molestation orders, and other legal documents, providing you with peace of mind during the legal proceedings.";
            case 'corporate':
                return "Ensure that your legal documents are delivered promptly and professionally with our process serving service. We specialise in serving court orders, such as non-molestation orders, summons, and other legal documents, to the intended recipients within the UK.";
            default:
                return "Our professional process serving ensures legal documents are delivered efficiently and reliably.\n" +
                    "We offer nationwide coverage across the UK, handling the delivery of legal documents with precision and confidentiality.\n" +
                    "Our experienced team ensures timely service, adhering to all legal requirements and providing proof of delivery.\n" +
                    "Whether you need urgent same-day service or regular process serving, we tailor our services to meet your specific needs.";
        }
    }

    return <>

        <Banner title={"Our Services"} description={typeTitle()} banner={typeBanner(servicesType())} />

        <div className={"section-lg bg-gray-lighter text-center text-md-left"}>
            <div className={"container"}>
                {(servicesType ?? "").length > 0 && <p>{typeDescription()}</p>}

                {showService('surveillance') &&
                    <ServiceSection title={"Covert Surveillance"} image={surveillanceImage()}
                                    description={surveillanceDescription()}
                                    link={"/book-surveillance" + forwardTypeParam()}
                                    instantQuote={true}/>
                }

                {showService('addressTracing') &&
                    <ServiceSection title={"Address Tracing"} image={addressTracingServiceImage}
                                    description={addressTracingDescription()}
                                    link={"/contact"}
                                    instantQuote={false}/>
                }

                {showService('vehicleTracking') &&
                    <ServiceSection title={"Covert Vehicle Tracking"} image={vehicleTrackingServiceImage}
                                    description={vehicleTrackingDescription()}
                                    link={"/contact"}
                                    instantQuote={false} />
                }

                {showService('processServing') &&
                    <ServiceSection title={"Process Serving"} image={processServeServiceImage}
                                    description={processServingDescription()}
                                    link={"/contact"}
                                    instantQuote={false} />
                }

                {/*{showService('processServing') &&*/}
                {/*    <ServiceSection title={"Corporate surveillance"} image={corporateInvestigationServiceImage}*/}
                {/*                    description={"Operating across the UK, we provide specialised corporate surveillance services to protect your business interests and guard against internal threats.\n" +*/}
                {/*                        "Monitor employee conduct, investigate potential misconduct, or gather evidence for legal proceedings with our discreet and efficient surveillance solutions.\n" +*/}
                {/*                        "Our goal is to deliver accurate and timely intelligence, enabling you to make informed decisions and safeguard your corporate assets."}*/}
                {/*                    link={"/contact"} />*/}
                {/*}*/}

                {/*{showService('closeProtection') &&*/}
                {/*    <ServiceSection title={"Close Protection"} image={closeProtectionServiceImage}*/}
                {/*                    description={"Close protection ensures your safety in all situations with personal security services.\n" +*/}
                {/*                        "Our highly trained team offers protection from potential threats for both short-term events and long-term needs.\n" +*/}
                {/*                        "Operating across the UK, we tailor our close protection services to meet your specific requirements."}*/}
                {/*                    link={"/contact"} />*/}
                {/*}*/}

            </div>
        </div>
    </>
}
export default Services;