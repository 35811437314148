import React from "react";
import UsersResponse from "./UsersResponse";
import UserResponseModel from "../model/UserResponseModel";
import AdminUserTableRow from "./AdminUserTableRow";

type AdminOperationsSectionProps = {
    title: string
    users: UsersResponse | undefined
}

function AdminUsersSection(props: AdminOperationsSectionProps) {
    return <>

        <h4>{props.title}</h4>

        <table>
            <tbody>
                <tr>
                    <th>Name</th>
                    <th>Surname</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Created At</th>
                    <th>Id</th>

                </tr>

            {props.users?.users.map((user: UserResponseModel) => (
                <AdminUserTableRow user={user} />
            ))}

            </tbody>

        </table>


    </>
}
export default AdminUsersSection;