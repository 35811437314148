import React, {useEffect} from 'react';
import './App.css';
import Main from './Main';
import {useLocation} from 'react-router-dom';
import {initializeGA} from './components/util/analytics';
import {loadGoogleAdsTag} from './components/util/analytics';
import ReactGA from 'react-ga4';
import ScrollToTop from "./components/ScrollToTop";

function App() {

    const location = useLocation();

    useEffect(() => {
        if (window.location.href.includes("irisinvestigators.com")) {
            console.log("Enabling Analytics");
            initializeGA();

            // Load Google Ads gtag.js script
            loadGoogleAdsTag();
            ReactGA.send({ hitType: "pageview", page: location.pathname });
        } else {
            console.log("Analytics not enabled. Current location: " + window.location.href );
        }
    }, [location]);



    return (
        <>
            <div>
                <Main/>
                <ScrollToTop />
            </div>
        </>
    );
}

export default App;
