import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import axiosClient from "./config/AxiosConfig";

export const UserVerifyRequest = (id: string, code: string) => { return { id: id, code: code } }

function VerifyEmailAddress() {

    const [formSentSuccessfully, setFormSentSuccessfully] = useState(false);
    const [formFailed, setFormFailed] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        verifyEmail()
    }, []);

    function verifyEmail() {
        const queryParameters = new URLSearchParams(window.location.search)
        const email = queryParameters.get("email");
        const code = queryParameters.get("code");
        console.log("Verifying user. email: " + email + ", code: " + code);
        if (email != null && code != null) {
            try {
                const response = axiosClient().post('/user-service/user/verify', UserVerifyRequest(email, code));
                console.log("SUCCESS:" + response);
                setFormSentSuccessfully(true);
                setFormFailed(false);
                setTimeout(() => {
                    setShouldRedirect(true);
                }, 3000);
            } catch (error) {
                console.error("FAILED:" + error);
                setFormFailed(true);
            }
        }
    }

    return (
        <>
            {shouldRedirect && <Navigate replace to="/login" />}
            <section className="section-md bg-default">
                <div className="container">
                    <div className="row row-50">
                        {!formSentSuccessfully && !formFailed &&
                            <div className="col-md-7 col-lg-8">
                                <h4 className="heading-decorated">Verifying your account.</h4>
                                <p>Please wait</p>
                            </div>
                        }
                        {formFailed &&
                            <div className="alert alert-danger" role="alert">
                                Failed to verify your account. Please try again later.
                            </div>
                        }
                        {formSentSuccessfully &&
                            <div className="alert alert-success" role="alert">
                                Your account has been verified successfully. You will be redirected in a few seconds.

                                <p><a href={"/login"}>Click here to go to the login page</a> </p>
                            </div>
                        }
                    </div>
                </div>
            </section>

        </>
    );
}
export default VerifyEmailAddress;