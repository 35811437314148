import React from "react";
import ReactGA from "react-ga4";

type AnalyticsButtonTrackerProps = {
    action: string;
}

export function trackButtonAnalytics(props: AnalyticsButtonTrackerProps) {

    console.log("Sending analytics: " + props.action);
    ReactGA.event({
        category: 'User',
        action: "Click Button",
        label: props.action
    });

}