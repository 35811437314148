import React from "react";

const PrivacyPolicy = () => {
    return (
        <>
            <section className="section section-lg section-relative bg-transparent">
                <div className="container">
                    <h1>Privacy Policy</h1>

                    <p className={"text-left"}>
                        <p>8. Privacy Policy</p>
                            <p>This Privacy Policy explains how we collect, use, and disclose information about you. By using your information, we can provide the product or service you’ve asked for. The use of your information helps us understand your interests and provide personalised information that meets your requirements.
                            What is Personal Data “Personal data” is any information about a living individual which allows them to be identified from that data (e.g. names, photographs, videos, email addresses or addresses). Identification can be directly using the data itself or by combining it with other information which helps to identify a living individual. The processing of personal data is governed by the legislation applicable in the United Kingdom including the General Data Protection Regulation (the “GDPR”) and the Data Protection Act 2018.
                            Your Consent By completing our forms (for example through our website or sent to you following your enquiry, or by the continued use of our services), you consent to the use of your personal information in accordance with this Policy. This includes any sensitive personal information that you may provide, such as details of any case history, medical conditions, sexuality, political affiliation or leaning, union membership etc that you may mention in a post or in an email to us in relation to a service you may require. You may withdraw such consent at any time by emailing: info@iris-investigations.co.uk</p>


                        <p>How We Use and Share Your Information</p>
                            <p>Deliver Services: We use your information to provide you with membership administration, the requested products and services or request for assistance, to provide a greater personalised experience and to manage our relationship with you.
                            Improve Experience and Personalisation: We use your information to monitor, improve, and protect our products, content, services, and website, both online and offline.
                            Market Research and Analytics: We may use your information to conduct market research in order to improve the services we offer and to develop new products and services.
                            Deliver Relevant Advertising: We use targeted advertising to provide online or email promotions that we believe are more relevant to your interests.
                            Relevant Communications: Unless you have told us otherwise, we may use your information to send you newsletters, bulletins and other information about us and our services, projects, or campaigns.
                            Sharing with Third Parties: We will only disclose your information to associated or third-party companies for their purposes, including marketing, when we have your permission to do so.
                            Disclosures Required by Law: Your personal information will be disclosed where we are obliged by law to do so. We may also disclose your personal information where we are allowed by law to protect or enforce our rights or the rights of others and for the detection and prevention of crimes, such as fraud.
                        </p>

                        <p>User Access and Control</p>
                            <p>Controlling Direct Marketing: You can change your mind about receiving direct marketing from us by contacting us using the contact details provided.
                            Requesting Copies of Your Personal Information: You may request a copy of your personal information from IRIS investigators, which we may hold about you without charge. You can also ask us to correct any inaccuracies in your personal information.
                            Retention of Personal Information: We will retain your information for as long as necessary for the uses set out in this Policy. Personal information that is not or no longer pertinent to us will be erased or shredded as per our Data Retention Policy.
                            </p>


                        </p>

                    <p>9. Limitations of Liability</p>
                    <p>IRIS investigators will make every effort to deliver services as agreed, but cannot guarantee specific outcomes.</p>
                    <p>IRIS investigators is not liable for any indirect, incidental, or consequential damages arising from the use of our services.</p>
                    <p>In no event shall IRIS investigators' liability exceed the total amount paid by the Client for the services.</p>
                    <p>10. Termination IRIS investigators reserves the right to terminate services if the Client breaches any of these terms or engages in illegal activities.</p>
                    <p>11. Changes to Terms IRIS investigators reserves the right to modify these Terms & Conditions at any time. Clients will be notified of any significant changes.</p>
                    <p>12. Governing Law These Terms & Conditions are governed by and construed in accordance with the laws of the United Kingdom. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts of the United Kingdom.13. Contact Information For any questions or concerns regarding these Terms & Conditions, please contact us at:</p>


                </div>
            </section>
        </>
    );
}
export default PrivacyPolicy;