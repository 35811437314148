import React from "react";
import axios, {AxiosInstance} from "axios";

function axiosClient(): AxiosInstance {

    const authTokenKey = "authToken";
    const authToken = localStorage.getItem(authTokenKey);
    return axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'Authorization': `Bearer ${authToken}`,
        }
    });
}

export default axiosClient;