import React, {useState} from 'react';
import {Navigate} from "react-router-dom";
import axiosClient from "./config/AxiosConfig";

var html = require('react-escape-html');

interface ResetPasswordFormState {
    resetPasswordRequestId: string;
    verificationCode: string;
    password: string;
    passwordVerification: string;
}

export const ResetPasswordFormRequest = (resetPasswordRequestId: string,
                                         verificationCode: string,
                                         password: string,
                                         passwordVerification: string
) => {
    return {
        resetPasswordRequestId: resetPasswordRequestId,
        verificationCode: verificationCode,
        password: password,
        passwordVerification: passwordVerification
    }
}

function validatePassword(password: string) {
    var re = {
        capital: /(?=.*[A-Z])/,
        digit: /(?=.*[0-9])/,
    };
    const validPassword = (
        password.length > 8 &&
        re.capital.test(password) &&
        re.digit.test(password) &&
        (password.includes("!") || password.includes("@") || password.includes("£") || password.includes("$") || password.includes("%") || password.includes("^") || password.includes("*") || password.includes("(") || password.includes(")") || password.includes("-") || password.includes("_") || password.includes("=") || password.includes("+") || password.includes("}") || password.includes("{") || password.includes("[") || password.includes("]") || password.includes("|") || password.includes(";") || password.includes(":") || password.includes("?") || password.includes(".") || password.includes(">") || password.includes(",") || password.includes("<"))
    )
    return validPassword;
}

function ResetPassword() {

    const [restCallInProgress, setRestCallInProgress] = useState(false);
    function formButtonClass(): string {
        if (!restCallInProgress) {
            return "button button-primary";
        } else {
            return "button-secondary";
        }
    }

    const [formSentSuccessfully, setFormSentSuccessfully] = useState(false);
    const [formFailed, setFormFailed] = useState(false);
    const [validationErrors, setValidationErrors] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    // validations
    const [validPassword, setValidPassword] = useState(true);
    const [passwordsDoNotMatchError, setPasswordsDoNotMatchError] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState('');

    var passwordInfoElement = html`
        <p>- a digit must occur at least once</p>
        <p>- a lower case letter must occur at least once</p>
        <p>- an upper case letter must occur at least once</p>
        <p>- a special character must occur at least once. The allowed special characters are the following:</p>
        <p>! @ £ $ % ^ * ( ) - _ = + } { [ ] | ; : ? . > , <</p>
        <p>- no whitespace (spaces) allowed in the entire string</p>
        <p>- has to be at least 8 characters long</p>`


    const [formData, setFormData] = useState<ResetPasswordFormState>({
        resetPasswordRequestId: '',
        verificationCode: '',
        password: '',
        passwordVerification: '',
    })

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, ["password"]: value}))
        const validPassword = validatePassword(value)
        setValidPassword(validPassword);
        if (!validPassword) {
            return
        }
        if (value === passwordMatch) {
            setPasswordsDoNotMatchError(false)
        } else {
            setPasswordsDoNotMatchError(true)
        }
        // console.log("change:" + name + ":" + value +", match value:" + passwordMatch + ",formData: " + formData.password);
    }


    const handleChangePasswordMatch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setPasswordMatch(value);
        if (formData.password === e.target.value) {
            setPasswordsDoNotMatchError(false)
            setFormData(prevData => ({...prevData, ["passwordVerification"]: value}))
        } else {
            setPasswordsDoNotMatchError(true)
        }
        // console.log("change2:" + name + ":" + value +", match value:" + passwordMatch + ",formData: " + formData.password);
    }

    function resetForm() {
        setFormData(prevData => ({...prevData, "resetPasswordRequestId": ''}))
        setFormData(prevData => ({...prevData, "verificationCode": ''}))
        setFormData(prevData => ({...prevData, "password": ''}))
        setFormData(prevData => ({...prevData, "passwordVerification": ''}))
    }

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormFailed(false);

        const queryParameters = new URLSearchParams(window.location.search)
        const resetPasswordRequestId = queryParameters.get("resetPasswordRequestId")!!;
        const verificationCode = queryParameters.get("verificationCode")!!;
        if (resetPasswordRequestId == null || verificationCode == null) {
            console.log("resetPasswordRequestId or verificationCode missing")
            setValidationErrors(true);
            return;
        }
        setFormData(prevData => ({...prevData, ["resetPasswordRequestId"]: resetPasswordRequestId || ""}))
        setFormData(prevData => ({...prevData, ["verificationCode"]: verificationCode || ""}))

        if (passwordsDoNotMatchError || !validPassword) {
            console.log("Passwords do not match")
            setValidationErrors(true);
            return;
        } else {
            setValidationErrors(false);
        }
        setRestCallInProgress(true);
        try {
            const response = await axiosClient().post('/user-service/forgotpassword/reset', ResetPasswordFormRequest(resetPasswordRequestId, verificationCode, formData.password, formData.passwordVerification));
            console.log("SUCCESS:" + response);
            setFormSentSuccessfully(true);
            setFormFailed(false);
            resetForm()
            setRestCallInProgress(false);
            setTimeout(() => {
                setShouldRedirect(true);
            }, 3000);
        } catch (error) {
            console.error("FAILED:" + error);
            setFormFailed(true);
            setRestCallInProgress(false);
        }
    }

    return (
        <>
            {shouldRedirect && <Navigate replace to="/login"/>}
            <section className="section-md bg-default">
                <div className="container">
                    <div className="row row-50">
                        <div className="col-md-5 col-lg-4">
                            <h4 className="heading-decorated">Reset Password</h4>
                            <p>Please use this form to set your new password</p>
                            <div className="alert alert-info" role="alert">
                                Password Rules:
                                <div dangerouslySetInnerHTML={passwordInfoElement}/>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-8">
                            <h4 className="heading-decorated">New Password Form</h4>

                            {formSentSuccessfully &&
                                <div className="alert alert-success" role="alert">
                                    <h5>Your password has changed successfully.</h5>
                                    <p>You will be forwarded to the login screen shortly.</p>
                                </div>
                            }
                            {formFailed &&
                                <div className="alert alert-danger" role="alert">
                                    Request failed. Please try again later.
                                </div>
                            }
                            {validationErrors &&
                                <div className="alert alert-warning" role="alert">
                                    Please set a valid password.
                                </div>
                            }

                            {!formSentSuccessfully &&
                                <form className="rd-mailform rd-mailform_style-1" data-form-output="form-output-global"
                                      data-form-type="contact" onSubmit={handleSubmit}>

                                    <div className="form-wrap form-wrap_icon linear-icon-key">
                                        <input className="form-input" id="register-password" type="password" name="password"
                                               onChange={handleChangePassword} value={formData.password}/>
                                        <label className="form-label" htmlFor="register-password">Your password</label>
                                    </div>
                                    {passwordsDoNotMatchError &&
                                        <div className="alert alert-warning alert-form" role="alert">
                                            Your password does not match.
                                        </div>
                                    }
                                    <div className="form-wrap form-wrap_icon linear-icon-key">
                                        <input className="form-input" id="register-password-match" type="password" name="password-match"
                                               onChange={handleChangePasswordMatch}
                                        />
                                        <label className="form-label" htmlFor="register-password-match">Your password again</label>
                                    </div>
                                    {!formSentSuccessfully && <button  disabled={restCallInProgress} className={"button " + formButtonClass()} type="submit">Change Password</button>}
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default ResetPassword;