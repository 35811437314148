import React from "react";
import bannerImage from '../images/banner_1.jpg';

type BannerProps = {
    title: string;
    description: string | undefined;
    banner: string | undefined;
}



function Banner(props: BannerProps) {

    const bannerStyle = {
        backgroundImage: `url(${bannerImageUrl()})`,
        backgroundSize: 'cover',
    };

    function bannerImageUrl(): string {
        if (props.banner === undefined) {
            return bannerImage
        }
        return props.banner
    };

    return <>

        <section className="text-center">
            <section className="section parallax-container">
                <div className="parallax-content parallax-header parallax-light " style={bannerStyle}>
                    <div className="parallax-header__inner">
                        <div className="parallax-header__content">
                            <div className="container">
                                <div className="row justify-content-sm-center">
                                    <div className="col-md-10 col-xl-8">
                                        <h2 className="heading-decorated main-page-header" >{props.title}</h2>
                                        {props.description !== undefined && <h4>{props.description}</h4>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </section>

    </>
}

export default Banner;