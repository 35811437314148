import React, {useState} from 'react';
import axiosClient from "./config/AxiosConfig";

interface RequestForgotPasswordFormState {
    username: string;
}

function validatePhone(phone: string) {
    var re = {
        phone: /^([0-9]){11}$/,
    };
    const validPhone = (
        re.phone.test(phone)
    )
    return validPhone;
}

function RequestForgotPassword() {

    const [restCallInProgress, setRestCallInProgress] = useState(false);
    function formButtonClass(): string {
        if (!restCallInProgress) {
            return "button button-primary";
        } else {
            return "button-secondary";
        }
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [formSentSuccessfully, setFormSentSuccessfully] = useState(false);
    const [formFailed, setFormFailed] = useState(false);
    const [validationErrors, setValidationErrors] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        // console.log("change:" + name + ":" + value);
        setFormData(prevData => ({...prevData, [name]: value}))
    }

    const [formData, setFormData] = useState<RequestForgotPasswordFormState>({
        username: ''
    })

    function resetForm() {
        setFormData(prevData => ({...prevData, "username": ''}))
    }

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormFailed(false);

        if (!formData.username || (!emailRegex.test(formData.username) && validatePhone(formData.username))) {
            console.log("Phone or email not filled.")
            setValidationErrors(true);
            return;
        } else {
            setValidationErrors(false);
        }

        setRestCallInProgress(true);
        try {
            const response = await axiosClient().post('/user-service/forgotpassword/request', formData);
            console.log("SUCCESS:" + response);
            setFormSentSuccessfully(true);
            setFormFailed(false);
            resetForm()
            setRestCallInProgress(false);
        } catch (error) {
            console.error("FAILED:" + error);
            setFormFailed(true);
            setRestCallInProgress(false);
        }
    }

    return (
        <>
            <section className="section-md bg-default">
                <div className="container">
                    <div className="row row-50">
                        <div className="col-md-7 col-lg-8">
                            <h4 className="heading-decorated">Forgot Password</h4>

                            {formSentSuccessfully &&
                                <div className="alert alert-success" role="alert">
                                    <h5>Your forgot-password-request has been received.</h5>
                                    <p>If your email of phone is exists we will send you an email with instructions.</p>
                                    <p>If you don't receive your email soon, please check your spam folder.</p>
                                </div>
                            }
                            {formFailed &&
                                <div className="alert alert-danger" role="alert">
                                    Request failed. Please try again later.
                                </div>
                            }
                            {validationErrors &&
                                <div className="alert alert-warning" role="alert">
                                    Please enter a valid email or phone.
                                </div>
                            }

                            {!formSentSuccessfully &&
                            <form className="rd-mailform rd-mailform_style-1" data-form-output="form-output-global"
                                  data-form-type="contact" onSubmit={handleSubmit}>
                                <p className={"formFieldLabel"}>Your email or phone number</p>
                                <div className="form-wrap form-wrap_icon linear-icon-man">
                                    <input className="form-input" id="register-username" type="text" name="username"
                                           onChange={handleChange} value={formData.username}/>
                                </div>
                                {!formSentSuccessfully && <button disabled={restCallInProgress} className={"button " + formButtonClass()} type="submit">Submit</button>}
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default RequestForgotPassword;