import React from "react";
import FullOperationResponse from "../model/FullOperationResponse";

type AdminFullOperationProps = {
    fullOperation: FullOperationResponse
}

function AdminFullOperation(props: AdminFullOperationProps) {

    return <>

        <tr>

            <td>{props.fullOperation.operation.operationCode}</td>
            <td>{props.fullOperation.operation.isPaid}</td>
            <td>{props.fullOperation.operation.status}</td>
            <td>{props.fullOperation.operation.type}</td>
            <td>{props.fullOperation.operation.totalCost}</td>

            <td>{props.fullOperation.user.displayName}</td>
            <td>{props.fullOperation.user.email}</td>
            <td>{props.fullOperation.user.phone}</td>

        </tr>

    </>
}
export default AdminFullOperation;