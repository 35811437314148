import React, {useState} from 'react';
import {Navigate, NavLink} from "react-router-dom";
import {trackButtonAnalytics} from "./util/TrackButtonAnalytics";
import axiosClient from "./config/AxiosConfig";

interface LoginFormState {
    username: string;
    password: string;
}

interface AuthToken {
    token: string;
}

function Login() {

    const [formSentSuccessfully, setFormSentSuccessfully] = useState(false);
    const [formFailed, setFormFailed] = useState(false);
    const [validationErrors, setValidationErrors] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("/");

    const [restCallInProgress, setRestCallInProgress] = useState(false);
    function formButtonClass(): string {
        if (!restCallInProgress) {
            return "button button-primary";
        } else {
            return "button-secondary";
        }
    }

    const [formData, setFormData] = useState<LoginFormState>({
        username: '',
        password: '',
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}))
    }

    function resetForm() {
        setFormData(prevData => ({...prevData, "username": ''}))
        setFormData(prevData => ({...prevData, "password": ''}))
    }

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!formData.username || !formData.password) {
            setValidationErrors(true);
            return;
        } else {
            setValidationErrors(false);
        }
        trackButtonAnalytics({action: "Login"});
        setRestCallInProgress(true);
        try {
            const response = await axiosClient().post<AuthToken>('/user-service/auth/signin', formData)
            console.log("SUCCESS: token: " + response.data.token);
            localStorage.setItem("authToken", response.data.token)
            setFormSentSuccessfully(true);
            setFormFailed(false);
            resetForm()
            setTimeout(() => {
                const redirectPath = localStorage.getItem('loginRedirect');
                if (redirectPath === "SURVEILLANCE") {
                    console.log("Going from Login to SURVEILLANCE");
                    setRedirectUrl("/book-surveillance");
                    localStorage.removeItem('loginRedirect');
                }
                setShouldRedirect(true);
            }, 500);
            setRestCallInProgress(false);
        } catch (error) {
            console.error("FAILED:" + error);
            setFormFailed(true);
            setRestCallInProgress(false);
        }
    }

    return (
        <>
            {/* TODO - this should redirect to previous page */}
            {shouldRedirect && <Navigate replace to={redirectUrl} />}
            <section className="section-md bg-default">
                <div className="container">
                    <div className="row row-50">
                        <div className="col-md-5 col-lg-4">
                            <h4 className="heading-decorated">Login</h4>

                            <div className="alert alert-info" role="alert">
                                Username:
                                <br/>
                                <p>
                                    Your username is either your email address or your phone number.
                                </p>
                                <p>
                                    If you used an email during your registration, then that is your username.
                                    If you only used a phone number then that is your username.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-8">
                            <h4 className="heading-decorated">Login Form</h4>

                            {formSentSuccessfully &&
                                <div className="alert alert-success" role="alert">
                                    Login successful.
                                </div>
                            }
                            {formFailed &&
                                <div className="alert alert-danger" role="alert">
                                    Request failed. Please try again later.
                                </div>
                            }
                            {validationErrors &&
                                <div className="alert alert-warning" role="alert">
                                    Please fill all the fields and then submit the form.
                                </div>
                            }

                            {!formSentSuccessfully &&
                                <form className="rd-mailform rd-mailform_style-1" data-form-output="form-output-global"
                                  data-form-type="contact" onSubmit={handleSubmit}>
                                    <p className={"formFieldLabel"}>Your username (Email or Phone)</p>
                                    <div className="form-wrap form-wrap_icon linear-icon-man">
                                        <input className="form-input" id="contact-username" type="text" name="username"
                                               data-constraints="@Required" onChange={handleChange} value={formData.username}/>
                                    </div>

                                    <p className={"formFieldLabel"}>Your password</p>
                                    <div className="form-wrap form-wrap_icon linear-icon-key">
                                        <input className="form-input" id="contact-password" type="password" name="password"
                                               data-constraints="@Email @Required" onChange={handleChange} value={formData.password}/>
                                    </div>
                                    <button disabled={restCallInProgress} className={"button " + formButtonClass()} type="submit">Login</button>
                                </form>

                            }
                            <div>
                                <br/>
                                <ul className="rd-navbar-nav">
                                <li>Click here to <NavLink to='/register'>Register</NavLink></li>
                                <li>Click here to <NavLink to='/forgotPassword'>Forgot password</NavLink></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Login;