import ReactGA from 'react-ga4';

export function initializeGA() {
    ReactGA.initialize('G-Y3XRTF8HJQ');
};

const googleAdsTag = "AW-16667975415";
export const loadGoogleAdsTag = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAdsTag}`;
    document.head.appendChild(script);

    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        window.gtag = gtag;

        window.gtag('js', new Date());
        window.gtag('config', googleAdsTag);
    };
};