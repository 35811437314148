import React from 'react';

function Footer() {
    return (
        // <!-- Page Footer-->
        <footer className="footer-minimal">
            <div className="container">
                <ul className="list-xxs">
                    <li>
                        <dl className="list-terms-minimal">
                            <dt>E-mail</dt>
                            <dd><a href="mailto:info@irisinvestigators.com">info@irisinvestigators.com</a></dd>
                        </dl>
                    </li>
                </ul>
                <ul className="list-inline-sm footer-minimal__list">
                    <li><a className="icon icon-sm icon-primary fa fa-facebook" href="#"></a></li>
                    <li><a className="icon icon-sm icon-primary fa fa-twitter" href="#"></a></li>
                    <li><a className="icon icon-sm icon-primary fa fa-youtube" href="#"></a></li>
                </ul>
                <p className="rights"><span>Iris Investigators</span><span>&nbsp;&copy;&nbsp;</span><span className="copyright-year"></span>.</p>
            </div>
        </footer>
    )
}

export default Footer;