import React from "react";

const TermsAndConditions = () => {
    return (
        <>
            <div className="App">

                <section className="section section-lg section-relative bg-transparent">
                    <div className="container">
                <h1>Terms &amp; Conditions</h1>

                <p className={"text-left"}>

                    Welcome to IRIS investigators. By accessing and using our services, you agree to comply with and be bound by the following terms and conditions.

                    <p>1. Definitions</p>
                    <p>"Client" refers to any individual or entity that engages IRIS investigators for services.
                    "Operator" refers to the private investigators and operatives employed or contracted by IRIS investigators.
                        "Services" refers to the investigative and related services provided by IRIS investigators.</p>

                    <p>2. Acceptance of Terms By booking our services, the Client agrees to be bound by these Terms & Conditions, as well as any additional terms provided during the booking process.</p>
                    <p>3. Services IRIS investigators provides investigative services tailored to the Client's needs as outlined in the booking and briefing process. We commit to delivering services with professionalism and discretion.</p>
                    <p>4. Booking and Payment</p>
                    <p>Clients must use the online booking tool to schedule services.
                        Full payment is required before IRIS investigators will assign the case and commence services.
                        Any additional costs incurred during the investigation will be communicated and must be approved by the Client.</p>

                    <p>5. Surveillance Services</p>
                        <p>Surveillance is provided as booked; however, IRIS investigators cannot guarantee specific results, as we cannot control the actions of the subject under surveillance.
                    It is possible that the subject may be lost during surveillance due to unforeseen circumstances. In such cases, IRIS investigators is not at fault.
                    As we provide covert surveillance, there may be instances where maintaining continuous visual contact with the subject is not possible. We will endeavour to minimise such occurrences but cannot guarantee uninterrupted surveillance.</p>

                        <p>6. Client Responsibilities</p>
                        <p>Clients must provide accurate and complete information during the briefing process.
                    Clients must not request services for illegal activities.
                    Clients are responsible for complying with all relevant laws and regulations.</p>

                        <p>7. Confidentiality and GDPR Compliance</p>
                        <p>IRIS investigators commits to maintaining the confidentiality of all Client information and case details, except where disclosure is required by law.
                    We are committed to complying with the General Data Protection Regulation (GDPR) and any other applicable data protection legislation. This means we ensure that any personal data we collect is:
                    Processed lawfully, fairly, and transparently.
                    Collected for specified, explicit, and legitimate purposes.
                    Adequate, relevant, and limited to what is necessary.
                    Accurate and kept up to date.
                    Stored only as long as necessary.
                    Secured with appropriate technical and organisational measures to protect against unauthorised or unlawful processing, accidental loss, destruction, or damage.</p>


                        <p>8. Privacy Policy This Privacy Policy explains how we collect, use, and disclose information about you. By using your information, we can provide the product or service you’ve asked for. The use of your information helps us understand your interests and provide personalised information that meets your requirements.
                    What is Personal Data “Personal data” is any information about a living individual which allows them to be identified from that data (e.g. names, photographs, videos, email addresses or addresses). Identification can be directly using the data itself or by combining it with other information which helps to identify a living individual. The processing of personal data is governed by the legislation applicable in the United Kingdom including the General Data Protection Regulation (the “GDPR”) and the Data Protection Act 2018.
                    Your Consent By completing our forms (for example through our website or sent to you following your enquiry, or by the continued use of our services), you consent to the use of your personal information in accordance with this Policy. This includes any sensitive personal information that you may provide, such as details of any case history, medical conditions, sexuality, political affiliation or leaning, union membership etc that you may mention in a post or in an email to us in relation to a service you may require. You may withdraw such consent at any time by emailing: info@iris-investigations.co.uk</p>

                </p>

                    </div>
                </section>
            </div>
        </>
    );
}
export default TermsAndConditions;