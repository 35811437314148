import React from "react";
import UserResponseModel from "../model/UserResponseModel";

type AdminFullOperationProps = {
    user: UserResponseModel
}

function AdminUserTableRow(props: AdminFullOperationProps) {

    return <>

        <tr>

            <td>{props.user.name}</td>
            <td>{props.user.surname}</td>
            <td>{props.user.email}</td>
            <td>{props.user.phone}</td>
            <td>{props.user.createdAt}</td>
            <td>{props.user.id}</td>

        </tr>

    </>
}
export default AdminUserTableRow;