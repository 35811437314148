import React from "react";

function WhyChooseOurService() {
    return <>

        <section className="section novi-bg novi-bg-img section-xl bg-default">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-md-10 col-xl-8">
                        <h2>Why choose our service?</h2>
                    </div>
                </div>
                <div className="row row-30">
                    <div className="col-sm-8 col-lg-4 text-center">
                        <article className="blurb blurb-minimal blurb-minimal_centered">
                            <div className="blurb-minimal__icon"><img src="../images/percent-01-48x48.svg" alt="" width="48" height="48"/>
                            </div>
                            <h3 className="heading-4 blurb__title">Transparent pricing</h3>
                            <p className="small text-secondary">No hidden or unexpected costs</p>
                        </article>
                    </div>
                    <div className="col-sm-8 col-lg-4">
                        <article className="blurb blurb-minimal blurb-minimal_centered">
                            <div className="blurb-minimal__icon"><img src="../images/user-msg-01-48x48.svg" alt="" width="48" height="48"/>
                            </div>
                            <h3 className="heading-4 blurb__title">Trusted expertise</h3>
                            <p className="small text-secondary">We deploy only the best and highly skilled investigators</p>
                        </article>
                    </div>
                    <div className="col-sm-8 col-lg-4">
                        <article className="blurb blurb-minimal blurb-minimal_centered">
                            <div className="blurb-minimal__icon"><img src="../images/bill-01-48x48.svg" alt="" width="48" height="48"/>
                            </div>
                            <h3 className="heading-4 blurb__title">Immediate quote</h3>
                            <p className="small text-secondary">Get a quote in minutes using our streamlined platform</p>
                        </article>
                    </div>
                </div>
            </div>
        </section>

    </>
}

export default WhyChooseOurService;