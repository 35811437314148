import React from "react";


export type DayPickerData = {
    date: Date;
    selected: Boolean;
    startTime: Number;
    endTime: Number;
}

export function SurveillanceDayCostCalculator(dayPickerData: DayPickerData, numberOfAgents: number): number {
    if (!dayPickerData.selected) {
        return 0;
    }

    let dayCost = 0;
    let normalHoursEndTime = 23;
    if (dayPickerData.endTime.valueOf() < 23) {
        normalHoursEndTime = dayPickerData.endTime.valueOf();
    }
    const normalHours = normalHoursEndTime - dayPickerData.startTime.valueOf();
    if (normalHours > 0) {
        dayCost = normalHours * 55;
    }

    let nightTimeHoursStartTime = 23;
    if (dayPickerData.startTime.valueOf() > nightTimeHoursStartTime) {
        nightTimeHoursStartTime = dayPickerData.startTime.valueOf();
    }
    const nightTimeHours = dayPickerData.endTime.valueOf() - nightTimeHoursStartTime;
    if (nightTimeHours > 0) {
        dayCost = dayCost + nightTimeHours * 65;
    }

    return dayCost * numberOfAgents;
}

export function isToday(date: Date) {
    date = new Date(date);

    const today = new Date();
    return date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate();
}

export const SurveillanceTimes = new Map([
    [7, "07:00"],
    [8, "08:00"],
    [9, "09:00"],
    [10, "10:00"],
    [11, "11:00"],
    [12, "12:00"],
    [13, "13:00"],
    [14, "14:00"],
    [15, "15:00"],
    [16, "16:00"],
    [17, "17:00"],
    [18, "18:00"],
    [19, "19:00"],
    [20, "20:00"],
    [21, "21:00"],
    [22, "22:00"],
    [23, "23:00"],
    [24, "00:00"],
    [25, "01:00 (Next day)"],
    [26, "02:00 (Next day)"],
    [27, "03:00 (Next day)"],
    [28, "04:00 (Next day)"],
    [29, "05:00 (Next day)"],
    [30, "06:00 (Next day)"],
    [31, "07:00 (Next day)"]
])