import React from "react";
import FullOperationResponse from "../model/FullOperationResponse";
import AdminFullOperation from "./AdminFullOperation";
import OperationsResponse from "./OperationsResponse";

type AdminOperationsSectionProps = {
    title: string
    operations: OperationsResponse | undefined
}

function AdminOperationsSection(props: AdminOperationsSectionProps) {
    return <>

        <h4>{props.title}</h4>

        <table>
            <tbody>
                <tr>
                    <th>Operation Code</th>
                    <th>Is Paid</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Total Cost</th>
                    <th>User</th>
                    <th>Email</th>
                    <th>Phone</th>
                </tr>

            {props.operations?.operations.map((operation: FullOperationResponse) => (
                <AdminFullOperation fullOperation={operation} />
            ))}

            </tbody>

        </table>


    </>
}
export default AdminOperationsSection;