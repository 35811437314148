import React, {useEffect, useState} from "react";
import Banner from "./Banner";
import {useLocation} from "react-router-dom";
import SurveillanceOperationModel from "./model/SurveillanceOperationModel";
import axiosClient from "./config/AxiosConfig";


function Checkout() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const operationId = queryParams.get('operationId')

    const [operation, setOperation] = useState<SurveillanceOperationModel>()
    const [operationPaid, setOperationPaid] = useState(false);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const fetchData = async () => {
            try {
                const response = await axiosClient().get<SurveillanceOperationModel>('/operations-service/operations/surveillance/' + operationId);
                console.log("SUCCESS:" + response);
                console.log("Operation id: " + response.data.id + ", operation code: " + response.data.operationCode + ", isPaid: " + response.data.isPaid);
                setOperation(response.data);

                if (response.data.isPaid) {
                    setOperationPaid(true);
                }
            } catch (error) {
                console.error("FAILED:" + error);
            }
        }

        if (!operationPaid) {
            fetchData();
            intervalId = setInterval(fetchData, 5000); // Poll every 5 seconds
        }

        // Cleanup interval on component unmount or when polling stops
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [operationPaid]);

    function operationType(): string {
        if (operation?.type === "SURVEILLANCE") {
            return "Covert Surveillance";
        }
        return "";
    }


    return <>

        <Banner title={"Checkout"} description={undefined} banner={undefined}/>

        <div className={"container"}>

            <div className={"section-lg text-center text-md-left"}>
                <div id={"checkoutForm"} className={"container text-center"}>
                    {/*<p>OperationId: {operation?.id}</p>*/}
                    <h2>{operationType()}</h2>
                    <h4>Operation Code: {operation?.operationCode}</h4>
                    <h5>Total cost: £{operation?.totalCost}</h5>
                    <br/>
                    {!operationPaid &&
                        <div className="alert alert-info" role="alert">
                            <h4>Please wait while we are confirming your payment</h4>
                        </div>
                    }

                    {operationPaid &&
                        <div className="alert alert-success" role="alert">
                            <h3>Payment Successful</h3>
                        </div>
                    }
                    <p>Our team will contact you as soon as possible to discuss the details of your operation</p>
                </div>
            </div>
        </div>
    </>
}

export default Checkout;