import React from "react";
import cheatingBanner from "../../images/cheating-banner.jpeg";
import courtBanner from "../../images/court-banner.jpeg";
import safeBanner from "../../images/safe-banner.jpeg";
import findBanner from "../../images/find-banner.jpeg";
import corporateBanner from "../../images/corporate-banner.jpeg";

export function typeBanner(servicesType: string | undefined | null): string | undefined {
    switch (servicesType) {
        case 'cheating':
            return cheatingBanner;
        case 'court':
            return courtBanner;
        case 'safe':
            return safeBanner;
        case 'find':
            return findBanner;
        case 'corporate':
            return corporateBanner;
        default:
            return undefined;
    }
}