const About = () => {
    return <>

        <section className="section section-lg section-relative bg-transparent">
            <div className="container">
                <h2>About Us</h2>
                {/*<div className="row row-30 flex-lg-row-reverse align-items-center">*/}
                {/*    <p className="col-md-6 col-lg-7">*/}
                {/*        <div className="block-sm">*/}
                {/*            <h3>Rich</h3>*/}
                {/*            <h5>A Few Words About Me</h5>*/}
                {/*            <p className="lead-custom">Rich co-founded IRIS out of frustration with how difficult and confusing it was for customers to hire a private investigator. He aimed to create a service where clients have more control, and investigators are properly matched to the right cases.</p>*/}
                {/*            <p>With over a decade of experience in operational UK law enforcement, Rich heads the operational running of IRIS. Driven by the goal to establish a firm that prioritises client needs and transparency in the investigative process.</p>*/}
                {/*            <p>Over the years, Richard has built an extensive network of specialist operators. This network ensures that IRIS offers top-tier investigators and operators. Each professional is carefully matched to the unique needs of every client, delivering unmatched service and expertise.</p>*/}
                {/*        </div>*/}
                {/*    </p>*/}
                {/*    <div className="col-md-6 col-lg-5"><img src="images/rich.jpeg" alt="" width="470" height="547"/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="row row-30 flex-lg-row-reverse align-items-center">*/}
                {/*    <div className="col-md-6 col-lg-7">*/}
                {/*        <div className="block-sm">*/}
                {/*            <h3>Dominic</h3>*/}
                {/*            <h5>A Few Words About Me</h5>*/}
                {/*            <p className="lead-custom">Dominic brings over 20 years of experience in the software industry, having served as a director for several Fortune 500 companies. His extensive background in technology and management has equipped him with a keen eye for detail and a commitment to excellence.</p>*/}
                {/*            <p>After utilizing a private investigator to locate a long-lost family member, Dominic recognized several gaps in the customer experience. This personal journey highlighted the need for a more streamlined and customer-centric approach in the private investigation industry.</p>*/}
                {/*            <p>It was during this time that he met Richard at a private investigation firm. Together, they identified an opportunity to revolutionize the industry by delivering a modern, superior customer experience.</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-6 col-lg-5"><img src="images/dom.jpeg" alt="" width="470" height="547"/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="row row-30 flex-lg-row-reverse align-items-center">*/}
                {/*    <div className="col-md-6 col-lg-7">*/}
                {/*        <div className="block-sm">*/}
                {/*            <h3>Panos</h3>*/}
                {/*            <h5>A Few Words About Me</h5>*/}
                {/*            <p className="lead-custom">Panos is our technical co-founder, responsible for the web platform.</p>*/}
                {/*            <p>With over a decade of experience on various web &amp; mobile platforms. Has worked in multiple different sectors, such as banking, fin-tech, telecommunications and more. With his experience we make sure that the platform provides the easiest way for customers to hire a private investigator, making sure everything is done securely.</p>*/}
                {/*            <p>Panos decided to join the team and become one of the co-founders after Richard and Dominic showing him how frustrating it was until now to hire a private-investigator. He has set as his goal to create the best platform and make it easier for our customers.</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-6 col-lg-5"><img src="images/panos.jpg" alt="" width="470" height="547"/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div>
                    <p>IRIS Investigators is a unique platform that allows clients to quickly determine the right private investigation service for their specific situation, while maintaining complete control over the costs of hiring a private investigator.</p>
                    <p>IRIS Investigators are driven and passionate about their roles. All investigators have strong UK law enforcement backgrounds and/or military service. They have undergone extensive training both in and out of the public sector, in various special investigatory tactics such as Close Target Reconnaissance (CTR) and covert surveillance. The variety of investigators that IRIS Investigators offer means that we can match the right operative to a specific client who needs their particular skill set.</p>
                    <p>The founding team of IRIS Investigators brings a diverse range of professional backgrounds. Our head of operations has spent over a decade in specialised UK law enforcement units, ensuring a strong foundation in this field. This is bolstered by a leadership team with Director experience in several Fortune 500 companies. Furthermore we are proud to have an exceptional software development team, enabling our platform to provide users with a transparent and immediate way to book and be matched with the best possible private investigator. </p>
                </div>

            </div>
        </section>


    </>;
}
export default About;