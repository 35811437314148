import React from "react";

type BlurbProps = {
    title: string;
    icon: string;
    linkTo: string;
    buttonTitle: string;
}

function Blurb(props: BlurbProps) {
    return <>

        <div className="col-md-9 col-lg-6">
            <article className="blurb blurb-circle">
                <a href={props.linkTo}>
                    <div className="unit flex-sm-row unit-spacing-md">
                        <div className="unit__left">
                            <div className="blurb-circle__icon"><span className={"icon " + props.icon}></span></div>
                        </div>
                        <div className="unit__body vertical-middle-outer">
                                                <span className=" vertical-middle-inner">
                                                    <p className="heading-6 blurb__title">{props.title}</p>
                                                    <p className="button button-primary blurbButton">{props.buttonTitle}</p>
                                                </span>
                        </div>
                    </div>
                </a>
            </article>
        </div>


    </>
}
export default Blurb;