import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {isToday} from "./util/SurveillanceUtil";

type Props = {
    date: Date;
    selected?: Boolean;
    startTime?: Number;
    endTime?: Number;
    callback: (dayPickerData: { date: Date; selected: Boolean; startTime: Number; endTime: Number }) => void
};

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const endTimes = new Map([
        [12, "12:00"],
        [13, "13:00"],
        [14, "14:00"],
        [15, "15:00"],
        [16, "16:00"],
        [17, "17:00"],
        [18, "18:00"],
        [19, "19:00"],
        [20, "20:00"],
        [21, "21:00"],
        [22, "22:00"],
        [23, "23:00"],
        [24, "00:00"],
        [25, "01:00"],
        [26, "02:00"],
        [27, "03:00"],
        [28, "04:00"],
        [29, "05:00"],
        [30, "06:00"],
        [31, "07:00"]
])

const startTimes = new Map([
    [7, "07:00"],
    [8, "08:00"],
    [9, "09:00"],
    [10, "10:00"],
    [11, "11:00"],
    [12, "12:00"],
    [13, "13:00"],
    [14, "14:00"],
    [15, "15:00"],
    [16, "16:00"],
    [17, "17:00"],
    [18, "18:00"],
    [19, "19:00"],
    [20, "20:00"],
    [21, "21:00"],
    [22, "22:00"],
    [23, "23:00"],
    [24, "00:00"],
    [25, "01:00"],
    [26, "02:00"]
])


function DayTimePicker(props: Props) {
    const [startTime, setStartTime] = useState(props.startTime?.valueOf() ?? calculateInitialTime());
    const [endTime, setEndTime] = useState(props.endTime?.valueOf() ?? calculateInitialTime() + 5);
    const [dateSelected, setDateSelected] = useState(props.selected ?? false);


    useEffect(() => {
        // console.log(`Day picker: ${props.date} is selected: ${props.selected}`);
        if (props.selected != undefined) {
            setDateSelected(props.selected ?? false);
        }
    },[]);

    function change(data: { date: Date; selected: Boolean; startTime: Number; endTime: Number }) {
        props.callback({
            date: data.date,
            selected: data.selected,
            startTime: data.startTime,
            endTime: data.endTime
        })
    }

    function dateSelectedButtonText(): string {
        if (dateSelected) {
            return "Remove";
        } else {
            return "Add";
        }
    }

    function dateSelectedButtonClass(): string {
        if (dateSelected) {
            return "button button-gray-light-outline";
        } else {
            return "button-gray-light-outline";
        }
    }

    function dateSelectedClicked() {
        // console.log("Date Selected Clicked " + props.date);
        setDateSelected(!dateSelected);
        change({date: props.date, selected: !dateSelected, startTime: startTime, endTime: endTime});
    }

    function dateSelectedTimePickerClass(): string {
        if (dateSelected) {
            return "time-picker-date-selected";
        } else {
            return "";
        }
    }

    const handleChangeStartTime = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = e.target;
        // setDateSelected(true);
        setStartTime(Number(value));
        const endTime = Number(value) + 5
        if (endTime - 5 <= Number(value)) {
            setEndTime(endTime);
        }
        change({date: props.date, selected: dateSelected, startTime: Number(value), endTime: endTime});
    }

    const handleChangeEndTime = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = e.target;
        // setDateSelected(true);
        if (Number(value) - 5 < startTime) {
            setEndTime(startTime + 5);
            change({date: props.date, selected: dateSelected, startTime: startTime, endTime: startTime + 5});
        } else {
            setEndTime(Number(value));
            change({date: props.date, selected: dateSelected, startTime: startTime, endTime: Number(value)});
        }
    }

    function createEndTimes() {
        let items = [];
        for (let i = startTime + 5; i <= 31; i++) {
            items.push(<option key={i} value={i}>{endTimes.get(i)}</option>);
            //here I will be creating my options dynamically based on
            //what props are currently passed to the parent component
        }
        return items;
    }

    function calculateInitialTime() {
        var initialTime = 7;
        const now = new Date();
        if (isToday(props.date) && now.getHours() < 12) {
            initialTime = 12;
        } else if (isToday(props.date)) {
            initialTime = now.getHours() + 5;
        }
        return initialTime;
    }

    function createStartTimes() {
        let items = [];


        for (let i = calculateInitialTime(); i <= 26; i++) {
            items.push(<option key={i} value={i}>{startTimes.get(i)}</option>);
        }
        return items;
    }

    function showDate() {
        if (isToday(props.date)) {
            return "Today"
        } else {
            return `${days[props.date.getDay()]}, ${format(props.date, 'do MMMM')}`
        }
    }

    return <>

        <div className={"dayTimePicker col-md-6 col-xl-3 text-center " + dateSelectedTimePickerClass() }>
            <div className={"dayTimePickerHeader"}>
                <div className={"box-counter__title black dayTimePickerDateTitle"}>{showDate()}</div>
            </div>

            <div className={"dayTimePickerBody text-center"}>
                {/*<div className="btn-group-vertical" role="group" aria-label="Basic example">*/}
                {/*    <button type="button" className="btn button button-gray-light-outline" onClick={() => morningClicked()}>Morning</button>*/}
                {/*    <button type="button" className="btn button button-gray-light-outline" onClick={() => afternoonClicked()}>Afternoon</button>*/}
                {/*    <button type="button" className="btn button button-gray-light-outline" onClick={() => eveningClicked()}>Evening</button>*/}
                {/*    <button type="button" className="btn button button-gray-light-outline" onClick={() => nightClicked()}>Night</button>*/}
                {/*</div>*/}


                <div className="timePickers text-center">
                    <div>
                        <span className={"time-picker-from-to"}>From</span>
                        <div className="dropdown">
                            <div className={"selectWrapper"}>
                                <select className={"btn dropdown-toggle"} value={startTime} onChange={handleChangeStartTime} name="startTime" id="starTime">
                                    {createStartTimes()}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                        <span className={"time-picker-from-to"}>To</span>
                        <div className="dropdown">
                            <div className={"selectWrapper"}>
                                <select className={"btn dropdown-toggle"} value={endTime} onChange={handleChangeEndTime} name="endTime" id="endTime">
                                    {createEndTimes()}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={ "select-date button " + dateSelectedButtonClass()} onClick={dateSelectedClicked}>{dateSelectedButtonText()}</div>
            </div>


        </div>

    </>;
}
export default DayTimePicker;