import React from "react";
import {NavLink} from "react-router-dom";

type ServiceSectionProps = {
    title: string;
    image: string;
    description: string;
    link: string;
    instantQuote: boolean | false;
}


function ServiceSection(props: ServiceSectionProps) {

    const serviceSectionImageStyle = {
        backgroundImage: `url(${props.image})`,
        backgroundSize: 'cover',
    };

    return <>

        <section className="bg-gray-lighter object-wrap">
            <div className="section-lg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <NavLink to={props.link} >
                                <h4 className="heading-decorated">{props.title}</h4>
                            </NavLink>
                            <p>{props.description}</p>
                            <NavLink to={props.link} >
                                <p className={"button button-primary"}>
                                    {props.instantQuote && <span>Instant Quote</span>}
                                    {!props.instantQuote && <span>Get a Quote</span>}
                                </p>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="object-wrap__body object-wrap__body-sizing-1 object-wrap__body-md-right bg-image" style={serviceSectionImageStyle}></div>
        </section>
        <br/>

    </>;

}

export default ServiceSection;