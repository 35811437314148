import React from "react";

function HowItWorks() {
    return <>

        <section className="section novi-bg novi-bg-img section-xl bg-default">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-md-10 col-xl-8">
                        <h3>How it works</h3>
                        <p className={"bold"}>Booking Covert Surveillance with us is simple and efficient</p>
                    </div>
                </div>
                <div className="row row-30 how-it-works-container">
                    <div className={"text-left"}>



                        <p className={"bold"}>Three Easy Steps to Get Started :</p>

                        <ul>
                            <li>1. Sign up on our platform to begin your booking process.</li>
                            <li>2. Use our intuitive booking system to choose your preferred dates and times.</li>
                            <li>3. After payment, you will receive a short briefing form for you to complete.</li>
                        </ul>

                        <p className={"bold"}>What to Expect:</p>

                        <ul>
                            <li>- At the conclusion of the surveillance operation, you will receive a comprehensive report.</li>
                            <li>- The report will include detailed movements of the subjects along with supporting images.</li>

                        </ul>

                        <p>An example report extract is avaliable below.</p>



                    </div>
                    <div className={"example-report-container"}>



                        <div className="example-report text-center"><img src="../images/example-report.png" alt=""/></div>
                    </div>
                </div>
            </div>
        </section>

    </>
}

export default HowItWorks;