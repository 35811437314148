import React, {useState} from 'react';
import {trackButtonAnalytics} from "./util/TrackButtonAnalytics";
import axiosClient from "./config/AxiosConfig";
import {NavLink} from "react-router-dom";

var html = require('react-escape-html');

interface RegisterFormState {
    email: string;
    password: string;
    name: string;
    surname: string;
    phone: string;
    acceptedTermsAndConditions: boolean;
}

function validatePassword(password: string) {
    var re = {
        capital: /(?=.*[A-Z])/,
        digit: /(?=.*[0-9])/,
    };
    const validPassword = (
        password.length > 8 &&
        re.capital.test(password) &&
        re.digit.test(password) &&
        (password.includes("!") || password.includes("@") || password.includes("£") || password.includes("$") || password.includes("%") || password.includes("^") || password.includes("*") || password.includes("(") || password.includes(")") || password.includes("-") || password.includes("_") || password.includes("=") || password.includes("+") || password.includes("}") || password.includes("{") || password.includes("[") || password.includes("]") || password.includes("|") || password.includes(";") || password.includes(":") || password.includes("?") || password.includes(".") || password.includes(">") || password.includes(",") || password.includes("<"))
    )
    return validPassword;
}

function validatePhone(phone: string) {
    var re = {
        phone: /^([0-9]){11}$/,
    };
    const validPhone = (
        re.phone.test(phone)
    )
    return validPhone;
}

function Register() {

    const [restCallInProgress, setRestCallInProgress] = useState(false);
    function formButtonClass(): string {
        if (!restCallInProgress) {
            return "button button-primary";
        } else {
            return "button-secondary";
        }
    }

    const [formSentSuccessfully, setFormSentSuccessfully] = useState(false);
    const [formFailed, setFormFailed] = useState(false);
    const [validationErrors, setValidationErrors] = useState(false);

    // validations
    const [validPassword, setValidPassword] = useState(true);
    const [passwordsDoNotMatchError, setPasswordsDoNotMatchError] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState('');
    const [phoneOrEmailFilled, setPhoneOrEmailFilled] = useState(true);

    const [validEmail, setValidEmail] = useState(true);
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFormData(prevData => ({...prevData, ["email"]: value}))
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setValidEmail(emailRegex.test(value));
    };

    const [validPhone, setValidPhone] = useState(true);
    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFormData(prevData => ({...prevData, ["phone"]: value}))
        const phoneValid = validatePhone(value)
        setValidPhone(phoneValid);
        console.log("Phone: " + value + " | valid: " + phoneValid);
    };

    // var passwordInfoElement = html`
    //     <p>- a digit must occur at least once</p>
    //     <p>- a lower case letter must occur at least once</p>
    //     <p>- an upper case letter must occur at least once</p>
    //     <p>- a special character must occur at least once. The allowed special characters are the following:</p>
    //     <p>! @ £ $ % ^ * ( ) - _ = + } { [ ] | ; : ? . > , <</p>
    //     <p>- no whitespace (spaces) allowed in the entire string</p>
    //     <p>- has to be at least 8 characters long</p>`

    var passwordInfoElement = html`
        <p>- digits</p>
        <p>- lowercase and uppercase characters</p>
        <p>- a special character (like an exclamation mark)</p>
        <p>- at least 8 characters long</p>`

    const [formData, setFormData] = useState<RegisterFormState>({
        email: '',
        password: '',
        name: '',
        surname: '',
        phone: '',
        acceptedTermsAndConditions: false
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        // console.log("change:" + name + ":" + value);
        setFormData(prevData => ({...prevData, [name]: value}))
    }

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, ["password"]: value}))
        const validPassword = validatePassword(value)
        setValidPassword(validPassword);
        if (!validPassword) {
            return
        }
        if (value === passwordMatch) {
            setPasswordsDoNotMatchError(false)
        } else {
            setPasswordsDoNotMatchError(true)
        }
        // console.log("change:" + name + ":" + value +", match value:" + passwordMatch + ",formData: " + formData.password);
    }


    const handleChangePasswordMatch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setPasswordMatch(value);
        if (formData.password === e.target.value) {
            setPasswordsDoNotMatchError(false)
        } else {
            setPasswordsDoNotMatchError(true)
        }
        // console.log("change2:" + name + ":" + value +", match value:" + passwordMatch + ",formData: " + formData.password);
    }

    const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        console.log("change-checkbox:" + value);
        setFormData(prevData => ({...prevData, ["acceptedTermsAndConditions"]: value}))
    }

    function resetForm() {
        setFormData(prevData => ({...prevData, "email": ''}))
        setFormData(prevData => ({...prevData, "password": ''}))
        setFormData(prevData => ({...prevData, "password2": ''}))
        setFormData(prevData => ({...prevData, "name": ''}))
        setFormData(prevData => ({...prevData, "surname": ''}))
        setFormData(prevData => ({...prevData, "phone": ''}))
        setFormData(prevData => ({...prevData, "acceptedTermsAndConditions": false}))
    }

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormFailed(false);
        trackButtonAnalytics({action: "Register"});

        if (!formData.email && !formData.phone) {
            console.log("Phone or email not filled.")
            setPhoneOrEmailFilled(false);
            return;
        } else {
            setPhoneOrEmailFilled(true);
        }

        if (passwordsDoNotMatchError) {
            console.log("Passwords do not match")
            return;
        }
        if ((!formData.phone && !formData.email) || !formData.name || !formData.password || !formData.acceptedTermsAndConditions) {
            console.log("Form failed")
            setValidationErrors(true);
            return;
        } else {
            setValidationErrors(false);
        }
        setRestCallInProgress(true);
        try {
            const response = await axiosClient().post('/user-service/user/register', formData);
            console.log("SUCCESS:" + response);
            setFormSentSuccessfully(true);
            setFormFailed(false);
            resetForm()
            setRestCallInProgress(false);
        } catch (error) {
            console.error("FAILED:" + error);
            setFormFailed(true);
            setRestCallInProgress(false);
        }
    }

    return (
        <>
            <section className="section-md bg-default">
                <div className="container">
                    <div className="row row-50">
                        <div className="col-md-5 col-lg-4">
                            <h4 className="heading-decorated">Register</h4>
                            <p>Please use this form to register on our platform</p>
                            {/*<p>You will need to provide an email and/or a phone number. If an email is provided then this will be your username.*/}
                            {/*    If only a phone is provided then this will be your username.</p>*/}

                            <div className="alert alert-info" role="alert">
                                Password must contain:
                                <div dangerouslySetInnerHTML={passwordInfoElement}/>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-8">
                            <h4 className="heading-decorated">Registration Form</h4>

                            {formSentSuccessfully &&
                                <div className="alert alert-success" role="alert">
                                    <h5>Thank you very much for registering with us.</h5>
                                    {formData.email !== "" && <p>Please check your email address and/or you phone for the link to verify your account.</p>}
                                    {formData.email === "" && <p>You can now <NavLink to='/login' >Login</NavLink>.</p>}
                                </div>
                            }
                            {formFailed &&
                                <div className="alert alert-danger" role="alert">
                                    Request failed. Please try again later.
                                </div>
                            }
                            {validationErrors &&
                                <div className="alert alert-warning" role="alert">
                                    Please fill all the required fields and then submit the form.
                                </div>
                            }
                            {!phoneOrEmailFilled &&
                                <div className="alert alert-warning" role="alert">
                                    Please provide an email or a phone.
                                </div>
                            }

                            {!formSentSuccessfully &&
                            <form className="rd-mailform rd-mailform_style-1" data-form-output="form-output-global"
                                  data-form-type="contact" onSubmit={handleSubmit}>

                                <p className={"formFieldLabel"}>Your name</p>
                                <div className="form-wrap form-wrap_icon linear-icon-man">
                                    <input className="form-input" id="register-name" type="text" name="name"
                                           onChange={handleChange} value={formData.name}/>
                                </div>

                                <p className={"formFieldLabel"}>Your surname</p>
                                <div className="form-wrap form-wrap_icon linear-icon-man">
                                    <input className="form-input" id="register-surname" type="text" name="surname"
                                           onChange={handleChange} value={formData.surname}/>
                                </div>

                                {!validEmail && formData.email !== "" &&
                                    <div className="alert alert-warning alert-form" role="alert">
                                        Please enter a valid email
                                    </div>
                                }
                                <p className={"formFieldLabel"}>Your e-mail</p>
                                <div className="form-wrap form-wrap_icon linear-icon-envelope">

                                    <input className="form-input" id="register-email" type="email" name="email"
                                           onChange={handleEmailChange} value={formData.email}/>
                                </div>

                                {!validPhone && formData.phone !== "" &&
                                    <div className="alert alert-warning alert-form" role="alert">
                                        Please enter a valid phone.
                                    </div>
                                }
                                <p className={"formFieldLabel"}>Your phone number</p>
                                <div className="form-wrap form-wrap_icon linear-icon-phone">
                                    <input className="form-input" id="register-phone" type="phone" name="phone"
                                           onChange={handlePhoneChange} value={formData.phone}/>
                                </div>

                                {!validPassword && formData.password !== "" &&
                                    <div className="alert alert-warning alert-form" role="alert">
                                        Please enter a valid password.
                                    </div>
                                }
                                <p className={"formFieldLabel"}>Your password</p>
                                <div className="form-wrap form-wrap_icon linear-icon-key">
                                    <input className="form-input" id="register-password" type="password" name="password"
                                           onChange={handleChangePassword} value={formData.password}/>
                                </div>

                                {passwordsDoNotMatchError &&
                                    <div className="alert alert-warning alert-form" role="alert">
                                        Your password does not match.
                                    </div>
                                }
                                <p className={"formFieldLabel"}>Your password again</p>
                                <div className="form-wrap form-wrap_icon linear-icon-key">
                                    <input className="form-input" id="register-password-match" type="password" name="password-match"
                                           onChange={handleChangePasswordMatch}
                                    />
                                </div>

                                <div className="form-wrap input-group mb-3">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <input type="checkbox" aria-label="Accept Terms &amp; Conditions"
                                                   onChange={handleChangeCheckbox}/>
                                            &nbsp; I accept the <a href={"/termsAndConditions"} target="_blank">Terms &amp; Conditions</a> and the <a
                                            href={"/privacyPolicy"} target="_blank">Privacy Policy</a>
                                        </div>
                                    </div>
                                </div>
                                {!formSentSuccessfully && <button disabled={restCallInProgress} className={"button " + formButtonClass()} type="submit">Register</button>}
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Register;