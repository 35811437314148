const FAQs = () => {
    return <>
        <section className="section-md bg-default">
            <div className="container">
                <div id="accordion" role="tablist">
                    <div className="card card-custom">
                        <div className="card-custom-heading" id="accordionHeading1" role="tab">
                            <h5 className="card-custom-title"><a className="collapsed" role="button"
                                                                 data-toggle="collapse" data-parent="#accordion"
                                                                 href="#accordionCollapse1"
                                                                 aria-controls="accordionCollapse1">How does IRIS investigators work?</a>
                            </h5>
                        </div>
                        <div className="card-custom-collapse collapse" id="accordionCollapse1" role="tabpanel"
                             aria-labelledby="accordionHeading1">
                            <div className="card-custom-body">
                                <p>IRIS offers a comprehensive range of investigative services with a focus on transparency and cost control. Our innovative tools, such as the surveillance calendar, provide you with complete control over scheduling and budgeting. We carefully assess the information you provide to match you with the most skilled investigator for your specific needs, ensuring an efficient and effective investigation.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom">
                        <div className="card-custom-heading" id="accordionHeading2" role="tab">
                            <h5 className="card-custom-title"><a className="collapsed" role="button"
                                                                 data-toggle="collapse" data-parent="#accordion"
                                                                 href="#accordionCollapse2"
                                                                 aria-controls="accordionCollapse2">How much does it cost?</a>
                            </h5>
                        </div>
                        <div className="card-custom-collapse collapse" id="accordionCollapse2" role="tabpanel"
                             aria-labelledby="accordionHeading2">
                            <div className="card-custom-body">
                                <p>Our upfront costing service allows you to see the exact cost of hiring an investigator before making any commitments. You have the flexibility to add or remove operatives and adjust the timing of the operation to suit your budget, giving you full financial control from the start.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom">
                        <div className="card-custom-heading" id="accordionHeading3" role="tab">
                            <h5 className="card-custom-title"><a className="collapsed" role="button"
                                                                 data-toggle="collapse" data-parent="#accordion"
                                                                 href="#accordionCollapse3"
                                                                 aria-controls="accordionCollapse3">How will the billing appear on my bank statement?</a>
                            </h5>
                        </div>
                        <div className="card-custom-collapse collapse" id="accordionCollapse3" role="tabpanel"
                             aria-labelledby="accordionHeading3">
                            <div className="card-custom-body">
                                <p>We use a nondescript company name other than IRIS investigators so that the transaction won't draw any undue attention. Please feel free to contact us if you would like more details.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom">
                        <div className="card-custom-heading" id="accordionHeading4" role="tab">
                            <h5 className="card-custom-title"><a className="collapsed" role="button"
                                                                 data-toggle="collapse" data-parent="#accordion"
                                                                 href="#accordionCollapse4"
                                                                 aria-controls="accordionCollapse4">How do I know which service is right for me?</a>
                            </h5>
                        </div>
                        <div className="card-custom-collapse collapse" id="accordionCollapse4" role="tabpanel"
                             aria-labelledby="accordionHeading4">
                            <div className="card-custom-body">
                                <p>Our website is designed to assess your current situation and recommend services that best meet your needs. Each service is described in detail, but if you require additional information or personalised recommendations, please do not hesitate to contact us. We are always available to discuss the different options and help you choose the best service for a successful investigation.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom">
                        <div className="card-custom-heading" id="accordionHeading5" role="tab">
                            <h5 className="card-custom-title"><a className="collapsed" role="button"
                                                                 data-toggle="collapse" data-parent="#accordion"
                                                                 href="#accordionCollapse5"
                                                                 aria-controls="accordionCollapse5">How do you differ from other detective agencies?</a>
                            </h5>
                        </div>
                        <div className="card-custom-collapse collapse" id="accordionCollapse5" role="tabpanel"
                             aria-labelledby="accordionHeading5">
                            <div className="card-custom-body">
                                <p>While there are many reputable detective agencies in the UK, some fail to provide adequate services or overcharge clients for incomplete work. At IRIS, we prioritise transparency and customisation. Our recommended services are tailored to your situation, and you maintain full control over the investigation's cost. We leverage our national network of private investigators, including ex-police and military personnel, to connect you with the best operator for your specific task, ensuring a high-quality and reliable service.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom">
                        <div className="card-custom-heading" id="accordionHeading6" role="tab">
                            <h5 className="card-custom-title"><a className="collapsed" role="button"
                                                                 data-toggle="collapse" data-parent="#accordion"
                                                                 href="#accordionCollapse6"
                                                                 aria-controls="accordionCollapse6">What types of investigations do you handle?</a>
                            </h5>
                        </div>
                        <div className="card-custom-collapse collapse" id="accordionCollapse6" role="tabpanel"
                             aria-labelledby="accordionHeading6">
                            <div className="card-custom-body">
                                <p>IRIS handles a wide variety of investigations, including but not limited to, surveillance, background checks, fraud investigations, infidelity cases, and corporate investigations. Our team is equipped to handle both personal and professional cases with discretion and efficiency.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom">
                        <div className="card-custom-heading" id="accordionHeading7" role="tab">
                            <h5 className="card-custom-title"><a className="collapsed" role="button"
                                                                 data-toggle="collapse" data-parent="#accordion"
                                                                 href="#accordionCollapse7"
                                                                 aria-controls="accordionCollapse7">How long does an investigation typically take?</a>
                            </h5>
                        </div>
                        <div className="card-custom-collapse collapse" id="accordionCollapse7" role="tabpanel"
                             aria-labelledby="accordionHeading7">
                            <div className="card-custom-body">
                                <p>The duration of an investigation can vary based on the complexity and scope of the case. You are in complete control of how much time the investigator is assigned to the task.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom">
                        <div className="card-custom-heading" id="accordionHeading8" role="tab">
                            <h5 className="card-custom-title"><a className="collapsed" role="button"
                                                                 data-toggle="collapse" data-parent="#accordion"
                                                                 href="#accordionCollapse8"
                                                                 aria-controls="accordionCollapse8">Is my information kept confidential?</a>
                            </h5>
                        </div>
                        <div className="card-custom-collapse collapse" id="accordionCollapse8" role="tabpanel"
                             aria-labelledby="accordionHeading8">
                            <div className="card-custom-body">
                                <p>Absolutely. At IRIS, we prioritise the confidentiality and privacy of our clients. All information you share with us is handled with the utmost discretion and is only used for the purpose of your investigation. We adhere to the General Data Protection Regulation (GDPR) to ensure that your data is protected and managed responsibly.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom">
                        <div className="card-custom-heading" id="accordionHeading9" role="tab">
                            <h5 className="card-custom-title"><a className="collapsed" role="button"
                                                                 data-toggle="collapse" data-parent="#accordion"
                                                                 href="#accordionCollapse9"
                                                                 aria-controls="accordionCollapse9">What qualifications do your investigators have?</a>
                            </h5>
                        </div>
                        <div className="card-custom-collapse collapse" id="accordionCollapse9" role="tabpanel"
                             aria-labelledby="accordionHeading3">
                            <div className="card-custom-body">
                                <p>Our investigators are highly trained professionals with extensive experience in various fields of investigation. O ur team members have backgrounds in law enforcement and the military, bringing a wealth of knowledge and expertise to each case. They possess relevant certifications and undergo continuous training to stay updated with the latest investigative techniques and technologies.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card card-custom">
                        <div className="card-custom-heading" id="accordionHeading10" role="tab">
                            <h5 className="card-custom-title"><a className="collapsed" role="button"
                                                                 data-toggle="collapse" data-parent="#accordion"
                                                                 href="#accordionCollapse10"
                                                                 aria-controls="accordionCollapse10">Can I track the progress of my investigation?</a>
                            </h5>
                        </div>
                        <div className="card-custom-collapse collapse" id="accordionCollapse10" role="tabpanel"
                             aria-labelledby="accordionHeading3">
                            <div className="card-custom-body">
                                <p>Yes, we offer regular updates and detailed reports during the investigation or operation.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>


    </>;
}
export default FAQs;